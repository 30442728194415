import { DateTime } from "luxon";

export default function Today() {
  const now = DateTime.now();
  const day = now.ordinal;

  return (
    <div>
      <div>Day {day} of 365</div>
      <progress max={365} value={day} />
    </div>
  );
}
