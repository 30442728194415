import styles from "./Loader.module.css";

export default function Loader() {
  return (
    <div className={styles.loader}>
      <div
        className={`${styles["loader-rect"]} ${styles["loader-rect1"]}`}
      ></div>
      <div
        className={`${styles["loader-rect"]} ${styles["loader-rect2"]}`}
      ></div>
      <div
        className={`${styles["loader-rect"]} ${styles["loader-rect3"]}`}
      ></div>
      <div
        className={`${styles["loader-rect"]} ${styles["loader-rect4"]}`}
      ></div>
    </div>
  );
}
