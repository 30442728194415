import { DateTime, Duration } from "luxon";

export const timezone = "America/Chicago";

export const thisYear = Array.from({ length: 365 }, (_, i) => false);

export const getDateObjFromISO = (ISODate) => {
  return DateTime.fromISO(ISODate, {
    zone: timezone,
  });
};

export const getFormattedDate = (date) => {
  return date.toFormat("LLL dd yyyy");
};

export const getFormattedTime = (date) => {
  return date.toFormat("hh:mm a");
};

export const getFormatted24HourTime = (date) => {
  return date.toFormat("HH:mm");
};

export const getSecondsFromHrsMins = (time) => {
  const timeArr = time.split(":");
  const secs = Number(timeArr[0]) * 3600 + Number(timeArr[1]) * 60;
  return secs;
};

export const getHrsMinsFromSeconds = (seconds) => {
  return Duration.fromMillis(seconds * 1000).toFormat("hh:mm");
};

export const getAvgTime = (times) => {
  if (!times.length) return;

  const numOfDays = times.length + 1;
  const timeSum = times.reduce((accumulator, currentValue, currentIndex) => {
    if (currentIndex === 1) {
      accumulator = getSecondsFromHrsMins(currentValue);
    }
    const secs = getSecondsFromHrsMins(currentValue);

    return accumulator + secs;
  });
  const avgSeconds = Math.round(timeSum / numOfDays);

  return getHrsMinsFromSeconds(avgSeconds);
};
