import { getFormatted24HourTime, getAvgTime } from "../utils/utils";

export default function AverageTime({ times }) {
  const filteredDays = times.filter((day) => {
    return day;
  });
  return (
    <div>
      {getAvgTime(
        filteredDays.map((datetime) => {
          return getFormatted24HourTime(datetime);
        })
      )}
    </div>
  );
}
