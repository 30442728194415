import { useMemo } from "react";
import { DateTime, Duration } from "luxon";
import { Chart } from "react-charts";

export default ({ thisYear }) => {
  const filteredDays = thisYear.filter((day) => {
    return day;
  });
  const timeData = filteredDays.map((day) => {
    return {
      x: DateTime.fromISO(day.toString()).toJSDate(),
      y: day.hour + day.minute / 60,
    };
  });

  console.log(timeData);

  const data = useMemo(
    () => [
      {
        label: "Wake up time",
        datums: timeData,
      },
    ],
    []
  );

  const axes = useMemo(
    () => [
      { primary: true, type: "time", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  const tooltip = useMemo(
    () => ({
      render: ({ datum, primaryAxis, getStyle }) => {
        return <CustomTooltip {...{ getStyle, primaryAxis, datum }} />;
      },
    }),
    []
  );

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "40vh",
        }}
      >
        <Chart data={data} axes={axes} tooltip={tooltip} />
      </div>
    </>
  );
};

function CustomTooltip({ getStyle, primaryAxis, datum }) {
  if (!datum) return null;

  const formattedDate = DateTime.fromJSDate(datum.originalDatum.x).toFormat(
    "LLL d"
  );
  const hours = String(Math.floor(datum.originalDatum.y)).padStart(2, "0");
  const hourDecimal = datum.originalDatum.y % 1;
  const minutes = String(Math.floor(hourDecimal * 60)).padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  return datum ? (
    <div
      style={{
        pointerEvents: "none",
      }}
    >
      <h3
        style={{
          display: "block",
          textAlign: "center",
        }}
      >
        {formattedDate} <br />
        {formattedTime}
      </h3>
    </div>
  ) : null;
}
