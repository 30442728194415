import { useState, useEffect } from "react";

import Loader from "./Loader";
import Chart from "./Chart";

import {
  thisYear,
  getDateObjFromISO,
  getFormattedDate,
  getFormattedTime,
} from "../utils/utils";

import styles from "./List.module.css";
import Today from "./Today";
import AverageTime from "./AverageTime";

const apiUrl = "/.netlify/functions/get/get.js";

function List() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(apiUrl)
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          const resultWithDateObj = result.map((day) => {
            const airtableDate = day.fields["Ready Time"];
            const date = getDateObjFromISO(airtableDate);
            day.date = date;
            thisYear[date.ordinal - 1] = date;
            return day;
          });
          setIsLoaded(true);
          setItems(resultWithDateObj);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [setError, setIsLoaded, setItems]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <h2>Today</h2>
      <Today />
      {items.length <= 0 ? (
        <p>No time record today.</p>
      ) : (
        <p>
          Today:{" "}
          {getFormattedTime(
            getDateObjFromISO(items[items.length - 1].fields["Ready Time"])
          )}
        </p>
      )}
      <hr />
      <h2>Average Time</h2>
      <AverageTime times={thisYear} />
      <hr />
      <Chart thisYear={thisYear} />
      <hr />
      <div>
        <div>
          <ol className={styles.list}>
            {thisYear.map((dateTime) => {
              return (
                <li
                  className={styles.listItem}
                  key={`day-${dateTime ? dateTime.ts : Math.random()}`}
                >
                  {dateTime && (
                    <div>
                      <div className={styles.listItemDate}>
                        {getFormattedDate(dateTime)} <br />
                      </div>
                      <div className={styles.listItemTime}>
                        {getFormattedTime(dateTime)}
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
}

export default List;
