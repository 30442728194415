import List from "./List";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <h1>⏰ Wake up 🔆</h1>
      </header>
      <main>
        <List />
      </main>
    </div>
  );
}

export default App;
